<template>
  <ProgressBarBase
    v-bind="($attrs, $props)"
    fill-color="background: linear-gradient(to bottom right, #2B8000 33.33%,
#66CC00) !important;"
  >
    <template #title>
      <slot name="title" />
    </template>
  </ProgressBarBase>
</template>

<script lang="ts">
import props from '~/components/ui/reactive/ProgressBarBase.vue';

export default {
  props,
};
</script>
